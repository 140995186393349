<template>
    <section class="pt-3">
        <div class="row mx-0">
            <div
            class="col-4 bg-white pt-3 br-8 overflow-auto custom-scroll"
            style="height: calc(100vh - 164px)"
            >
                <p class="text-general f-18 f-500 mb-2">
                    Lista de gráficas KPI
                </p>
                <div
                v-for="(grp, g) in graficasIndex"
                :key="g"
                class="row mx-0 cr-pointer mb-2"
                >
                    <div class="col" @click="goTo(grp.ref)">
                        <a class="btn-index" v-text="grp.title" />
                    </div>
                    <el-popover
                    placement="right"
                    width="300"
                    trigger="hover"
                    :disabled="grp.desactivado"
                    popper-class="p-2 br-8"
                    >
                        <p style="word-break:break-word;">
                            {{ grp.descripcion }}
                        </p>
                        <i slot="reference" class="icon-info-circled-alt f-18 text-general" />
                    </el-popover>
                </div>
            </div>
            <!-- 127px -->
            <div
            class="col ml-3 overflow-auto custom-scroll"
            style="height: calc(100vh - 164px)"
            >
                <!-- No dañar please -->

                <section class="position-relative">
                    <cargando v-if="loadingFirst" />
                    <grafica-kpi
                    ref-grafica="ingresoTotalSinImpuesto"
                    :source="ingresoTotalSinImpuesto"
                    descripcion-grafica="Gross Merchandise (Monto bruto sin impuesto de todas las ventas realizadas)"
                    titulo="GMV - Ingreso total sin impuestos"
                    />
                    <grafica-kpi
                    ref-grafica="costoProducto"
                    :source="costoProducto"
                    descripcion-grafica="Cost of goods to sell (Suma de los costos asociados a los productos a vender)"
                    titulo="COGS - Costos de producto"
                    />
                    <grafica-kpi
                    ref-grafica="cantidadPedidos"
                    :source="cantidadPedidos"
                    descripcion-grafica="Suma de las ordenes realizadas durante un equipo de tiempo"
                    titulo="ORDERS - Cantidad de pedidos"
                    :format-porcentaje="false"
                    />
                </section>
                <section class="position-relative">
                    <cargando v-if="loadingSecond" />
                    <grafica-kpi
                    ref-grafica="ticketPromedio"
                    :source="ticketPromedio"
                    descripcion-grafica="Division de GMV / Ordenes por un periodo de tiempo"
                    titulo="AVG TKT - Ticket promedio"
                    />
                    <grafica-kpi
                    ref-grafica="totalUtilidadBruta"
                    :source="totalUtilidadBruta"
                    descripcion-grafica="Average Revenue per User (Ingresos/Users en periodo de tiempo)"
                    titulo="ARPU - Total utilidad bruta / total usuarios diferentes"
                    />
                    <grafica-kpi
                    ref-grafica="utilidadBruta"
                    :source="utilidadBruta"
                    descripcion-grafica="Diferencia entre valor de compra del producto y su valor de venta"
                    titulo="REVENUE - Utilidad bruta"
                    />
                </section>
                <section class="position-relative">
                    <cargando v-if="loadingThird" />
                    <grafica-kpi
                    ref-grafica="utilidadBrutaGMV"
                    :source="utilidadBrutaGMV"
                    descripcion-grafica="Division entre revenue y GMV por un periodo de tiempo"
                    titulo="Margen % - Utilidad bruta / GMV"
                    />
                    <grafica-kpi
                    ref-grafica="leecherosPrimeraNavegacion"
                    :source="leecherosPrimeraNavegacion"
                    :titulo="`DESCARGAS - ${$config.vendedor + 's'} primer navegación`"
                    :descripcion-grafica="`Total de descargas por usuarios unicos de la app ${$config.vendedor + 's'}`"
                    :format-porcentaje="false"
                    />
                    <grafica-kpi
                    ref-grafica="clientesPrimeraNavegacion"
                    :source="clientesPrimeraNavegacion"
                    :titulo="`DESCARGAS - ${$config.cliente + 's'} primer navegación`"
                    :descripcion-grafica="`Total de descargas por usuarios unicos de la app ${$config.vendedor + 's'}`"
                    :format-porcentaje="false"
                    />
                </section>
                <section class="position-relative">
                    <cargando v-if="loadingFourth" />
                    <grafica-kpi
                    ref-grafica="leecherosDiferentes"
                    :source="leecherosDiferentes"
                    :titulo="`MAU - ${$config.vendedor + 's'} diferentes`"
                    descripcion-grafica="Monthly Active Users. Suma de usuarios unicos con compras en un periodo no mayor a 30 dias"
                    :format-porcentaje="false"
                    />
                    <grafica-kpi
                    ref-grafica="clientesDiferentes"
                    :source="clientesDiferentes"
                    :titulo="`MAU - ${$config.cliente + 's'} diferentes`"
                    descripcion-grafica="Monthly Active Users. Suma de usuarios unicos con compras en un periodo no mayor a 30 dias"
                    :format-porcentaje="false"
                    />
                    <grafica-kpi
                    ref-grafica="cantidadLeecheros"
                    :source="cantidadLeecheros"
                    :titulo="`TOTAL USERS - Cantidad de ${$config.vendedor + 's'}`"
                    :descripcion-grafica="`Total de users registrados en la app ${$config.vendedor + 's'}`"
                    :format-porcentaje="false"
                    />
                </section>

                <section class="position-relative">
                    <cargando v-if="loadingFifth" />
                    <grafica-kpi
                    ref-grafica="cantidadClientesRegistrados"
                    :source="cantidadClientesRegistrados"
                    :titulo="`TOTAL USERS - Cantidad de ${$config.cliente + 's'} registrados`"
                    :descripcion-grafica="`Total de users registrados en la app ${$config.vendedor}`"
                    :format-porcentaje="false"
                    />
                    <grafica-kpi
                    ref-grafica="cantidadArrancaAppLeechero"
                    :source="cantidadArrancaAppLeechero"
                    :titulo="`SESIONES - ${$config.vendedor + 's'} arranca app`"
                    descripcion-grafica="Total de sesiones unicas a la app"
                    :format-porcentaje="false"
                    />
                    <grafica-kpi
                    ref-grafica="sesionesUnicasPedido"
                    :source="sesionesUnicasPedido"
                    titulo="CONVERSION RATE - Sesiones unicas pedidos"
                    descripcion-grafica="Suma de sesiones unicas / ordenes"
                    :format-porcentaje="false"
                    />
                </section>
                <section class="position-relative">
                    <cargando v-if="loadingSixth" />
                    <grafica-kpi
                    ref-grafica="promedioPedidos"
                    :source="promedioPedidos"
                    :desactivar-popover="true"
                    :titulo="`Promedio de pedidos por ${$config.vendedor}`"
                    :format-porcentaje="false"
                    />
                    <grafica-kpi
                    ref-grafica="frecuenciaPedidosDia"
                    :source="frecuenciaPedidosDia"
                    :desactivar-popover="true"
                    titulo="Frecuencia promedio de compras al día"
                    :format-porcentaje="false"
                    />
                    <grafica-kpi
                    ref-grafica="satisfaccionUsuario"
                    :source="satisfaccionUsuario"
                    :titulo="`CSAT Satisfacción ${$config.vendedor + 's'}`"
                    descripcion-grafica="Porcentaje de respuestas positivas con respecto a la pregunta ¿Esta satisfecho con su compra? a cada orden entrega"
                    :format-porcentaje="false"
                    />
                </section>
                <section class="position-relative">
                    <cargando v-if="loadingSeventh" />
                    <grafica-kpi
                    ref-grafica="calificacionPedido"
                    :source="calificacionPedido"
                    :titulo="`NPS - Calificacion de ${$config.vendedor + 's'}`"
                    descripcion-grafica="Evaluaciones positivas (9 a 10) - Evaluaciones Negativas (1-6). Se realiza a todos los usuarios activos una vez al mes"
                    :format-porcentaje="false"
                    />
                    <grafica-kpi
                    ref-grafica="perdidaUsuarioMes"
                    :source="perdidaUsuarioMes"
                    titulo="CHURN - Perdida de usuarios por mes"
                    descripcion-grafica="1-CRT"
                    :format-porcentaje="false"
                    />
                    <grafica-kpi
                    ref-grafica="retencionUsuarioMes"
                    :source="retencionUsuarioMes"
                    titulo="CRT - Retención de usuarios por mes"
                    descripcion-grafica="Customer Retention Rate (Suma de usuarios no nuevos activos durante los ultimos 30 dias/ Suma de usuarios activos entre 30 y 60 dias)"
                    :format-porcentaje="false"
                    />
                </section>
                <section class="position-relative">
                    <cargando v-if="loadingEighth" />
                    <grafica-kpi
                    ref-grafica="lifeTime"
                    :source="lifeTime"
                    titulo="LTV - LifeTime Value"
                    descripcion-grafica="LifeTime Value (ARPU / CHURN)"
                    />
                    <div
                    ref="inventario"
                    class="row mx-0 py-3 bg-white br-8 mb-3"
                    >
                        <div class="col-6 f-18 text-general f-500">
                            Inventario de los cedis en los últimos 12 meses
                        </div>
                        <div class="col-6 f-18 text-general f-500 text-right">
                            Últimos 12 meses
                            <i class="icon-info-circled-alt f-18 text-general cr-pointer" />
                        </div>
                        <div class="d-flex col-12 justify-content-center p-2">
                            <label for="radio1" class="pr-1 d-flex align-items-end pb-2 col-auto cr-pointer">
                                <input id="radio1" v-model="radio" :value="1" type="radio" class="option-input radio black" />
                                <p class="f-14">Dinero</p>
                            </label>
                            <label for="radio2" class="pr-1 d-flex align-items-end pb-2 col-auto cr-pointer">
                                <input id="radio2" v-model="radio" :value="2" type="radio" class="option-input radio black" />
                                <p class="f-14">Productos</p>
                            </label>
                            <label for="radio3" class="pr-1 d-flex align-items-end pb-2 col-auto cr-pointer">
                                <input id="radio3" v-model="radio" :value="3" type="radio" class="option-input radio black" />
                                <p class="f-14">Unidades</p>
                            </label>
                            <label for="radio4" class="pr-1 d-flex align-items-end pb-2 col-auto cr-pointer">
                                <input id="radio4" v-model="radio" :value="4" type="radio" class="option-input radio black" />
                                <p class="f-14">Valor del producto (dinero/unidades)</p>
                            </label>
                        </div>
                        <div class="col-12 my-3">
                            <echart :options="inventarioCedis" />
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <!-- Partials -->
        <modal ref="modalInfoGrafica" :titulo="tituloModal" no-aceptar no-cancelar>
            <div class="row mx-0">
                <div class="col-12 text-general2 text-center f-16">
                    La asignación del IVA es una parte importante de la contabilidad de las empresas que pagan impuestos de conformidad con el sistema impositivo básico.
                </div>
            </div>
        </modal>
    </section>
</template>

<script>
import { mapGetters } from 'vuex'
import kpi from "~/services/estadisticas/kpi";
import moment from "moment";

const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++){
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

export default {
    components: {
        graficaKpi: () => import("./components/grafica"),
    },
    data(){
        return {
            radio:1,
            graficasIndex: [
                {
                    id: 1,
                    title: "GMV - Ingreso total sin impuestos",
                    descripcion: 'Gross Merchandise (Monto bruto sin impuesto de todas las ventas realizadas)',
                    ref: "ingresoTotalSinImpuesto",
                },
                {
                    id: 2,
                    title: "Costos de producto",
                    descripcion: 'Cost of goods to sell (Suma de los costos asociados a los productos a vender)',
                    ref: "costoProducto",
                },
                {
                    id: 3,
                    title: "Cantidad de pedidos",
                    descripcion: 'Suma de las ordenes realizadas durante un equipo de tiempo',
                    ref: "cantidadPedidos",
                },
                {
                    id: 4,
                    title: "Ticket promedio",
                    descripcion: 'Division de GMV / Ordenes por un periodo de tiempo',
                    ref: "ticketPromedio",
                },
                {
                    id: 5,
                    title: "ARPU - Total utilidad bruta / total usuarios diferentes",
                    descripcion: 'Average Revenue per User (Ingresos/Users en periodo de tiempo)',
                    ref: "totalUtilidadBruta",
                },
                {
                    id: 6,
                    title: "REVENUE - Utilidad bruta",
                    descripcion: 'Diferencia entre valor de compra del producto y su valor de venta',
                    ref: "utilidadBruta",
                },
                {
                    id: 7,
                    title: "Margen % Utilidad bruta / GMV",
                    descripcion: 'Division entre revenue y GMV por un periodo de tiempo',
                    ref: "utilidadBrutaGMV",
                },
                {
                    id: 8,
                    title: "DESCARGAS - Leecheros primer navegación",
                    descripcion: 'Total de descargas por usuarios unicos de la app Leechero',
                    ref: "leecherosPrimeraNavegacion",
                },
                {
                    id: 9,
                    title: "DESCARGAS - Clientes primer navegación",
                    descripcion: 'Total de descargas por usuarios unicos de la app Leechero',
                    ref: "clientesPrimeraNavegacion",
                },
                {
                    id: 10,
                    title: "MAU - Leecheros diferentes",
                    descripcion: 'Monthly Active Users. Suma de usuarios unicos con compras en un periodo no mayor a 30 dias',
                    ref: "leecherosDiferentes",
                },
                {
                    id: 11,
                    title: "MAU - Clientes diferentes",
                    descripcion: 'Monthly Active Users. Suma de usuarios unicos con compras en un periodo no mayor a 30 dias',
                    ref: "clientesDiferentes",
                },
                {
                    id: 12,
                    title: "TOTAL USERS - Cantidad de leecheros",
                    descripcion: 'Total de users registrados en la app Leechero',
                    ref: "cantidadLeecheros",
                },
                {
                    id: 13,
                    title: "TOTAL USERS - Cantidad de clientes registrados",
                    descripcion: 'Total de users registrados en la app Leechero',
                    ref: "cantidadClientesRegistrados",
                },
                {
                    id: 14,
                    title: "SESIONES - Leecheros arranca app",
                    descripcion: 'Total de sesiones unicas a la app',
                    ref: "cantidadArrancaAppLeechero",
                },
                {
                    id: 15,
                    title: "CONVERSION RATE - Sesiones unicas pedidos",
                    descripcion: 'Suma de sesiones unicas / ordenes',
                    ref: "sesionesUnicasPedido",
                },
                {
                    id: 16,
                    title: "Promedio de pedidos por leechero",
                    desactivado: true,
                    ref: "promedioPedidos",
                },
                {
                    id: 17,
                    title: "Frecuencia promedio de  compras al día",
                    desactivado: true,
                    ref: "frecuenciaPedidosDia",
                },
                {
                    id: 18,
                    title: "CSAT - Satisfacción leecheros",
                    descripcion: 'Porcentaje de respuestas positivas con respecto a la pregunta "¿Esta satisfecho con su compra?" a cada orden entrega',
                    ref: "satisfaccionUsuario",
                },
                {
                    id: 19,
                    title: "NPS - Calificación leecheros",
                    descripcion: 'Evaluaciones positivas (9 a 10) - Evaluaciones Negativas (1-6). Se realiza a todos los usuarios activos una vez al mes',
                    ref: "calificacionPedido",
                },
                {
                    id: 20,
                    title: "CHURN - Perdida de usuarios respecto compradores del mes pasado",
                    descripcion: '1-CRT',
                    ref: "perdidaUsuarioMes",
                },
                {
                    id: 21,
                    title: "CRT - Retención de usuarios respecto compradores del mes pasado",
                    descripcion: 'Customer Retention Rate (Suma de usuarios no nuevos activos durante los ultimos 30 dias/ Suma de usuarios activos entre 30 y 60 dias)',
                    ref: "retencionUsuarioMes",
                },
                {
                    id: 22,
                    title: "LTV - LifeTime Value",
                    descripcion: 'LifeTime Value (ARPU / CHURN)',
                    ref: "lifeTime",
                },
                {
                    id: 23,
                    title: "Inventario",
                    desactivado: true,
                    ref: "inventario",
                },
            ],
            tituloModal: '',
            loadingFirst: true,
            loadingSecond: true,
            loadingThird: true,
            loadingFourth: true,
            loadingFifth: true,
            loadingSixth: true,
            loadingSeventh: true,
            loadingEighth: true,
            ingresoTotalSinImpuesto: [],
            costoProducto: [],
            cantidadPedidos: [],
            ticketPromedio: [],
            totalUtilidadBruta: [],
            utilidadBruta: [],
            leecherosDiferentes: [],
            clientesDiferentes: [],
            cantidadLeecheros: [],
            cantidadClientesRegistrados: [],
            cantidadArrancaAppLeechero: [],
            sesionesUnicasPedido: [],
            promedioPedidos: [],
            frecuenciaPedidosDia: [],
            satisfaccionUsuario: [],
            calificacionPedido: [],
            retencionUsuarioMes: [],
            perdidaUsuarioMes: [],
            utilidadBrutaGMV: [],
            leecherosPrimeraNavegacion: [],
            clientesPrimeraNavegacion: [],
            lifeTime: [],
            inventarioData: {}
        };
    },
    computed: {
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
            id_moneda:'cedis/id_moneda',
        }),
        radioSelected(){
            if(this.radio == 1) return 'dinero'
            if(this.radio == 2) return 'productos'
            if(this.radio == 3) return 'unidades'
            if(this.radio == 4) return 'dineuni'
            return 'dinero'
        },
        inventarioCedis(){
            let datos = this.inventarioData.data
            let ultimo = this.inventarioData.ultimo

            if(_.isEmpty(datos) || !datos.length) return {}

            let meses = _.chain(datos)
                .map(el => el.mesF)
                .uniq()
                .value()

            let cedis = _.chain(datos)
                .map(el => el.cedi)
                .uniq()
                .value()

            let cedisObj = Object.values(cedis).reduce((acc, el) => ({...acc, [el]: [] }), {})

            let cedisWithData = datos.reduce((acc, el) => {
                acc[el.cedi].push(el[this.radioSelected])
                return acc
            }, cedisObj)


            let series = Object.keys(cedisWithData).map((el, key) => {
                return {
                    name: el,
                    type: "bar",
                    stack: "cedis",
                    barWidth: 18,
                    itemStyle: {
                        normal: {
                            color: getRandomColor(),
                        },
                    },
                    data: cedisWithData[el],
                }
            })

            let variacion = []

            let unCedis = cedis[0]

            for (let i = 0; i < cedisWithData[unCedis].length; i++){
                let suma = 0
                for (const cedis in cedisWithData){
                    suma += cedisWithData[cedis][i]
                }
                variacion.push(suma)

            }

            let nueva = []

            for (let i = 0; i < cedisWithData[unCedis].length; i++){

                if(i == 0 ){
                    nueva[i] = Number(ultimo[this.radioSelected]) > 0 ? Math.round((variacion[i] - Number(ultimo[this.radioSelected])) / Number(ultimo[this.radioSelected])) : 100
                    continue
                }

                nueva[i] = variacion[i - 1] > 0 ? Math.round(((variacion[i] - variacion[i - 1]) / variacion[i - 1]) * 100) : 100
            }

            series.push({
                name: "Variacion",
                type: "line",
                yAxisIndex: 1,
                symbol: "circle",
                symbolSize: 6,
                itemStyle: {
                    color: "#9508EB",
                    borderColor: "#9508EB",
                    width: 2,
                    shadowColor: "#9508EB",
                    shadowBlur: 2
                },
                lineStyle: {
                    color: "#9508EB",
                    width: 2,
                    shadowBlur: 2
                },
                data: nueva
            })

            return {

                tooltip: {
                    trigger: "axis",
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    formatter: (params) => {
                        let last = _.last(params)
                        let lastValor = this.agregarSeparadoresNumero(last.value || 100)

                        params.pop()

                        let total = params.reduce((acc, el) => acc += el.value, 0)

                        // console.log({params});
                        let cedis = params.map(el => {
                            let porcentaje = ((el.value * 100) / total) || 0
                            let valor = [1, 4].includes(this.radio) ? this.separadorNumero(el.value) : this.agregarSeparadoresNumero(el.value)

                            return `
                            <div class="row mx-0">
                                <div class="col pl-0">
                                    <span class="icon-blank f-14" style="color:${el.color}" />
                                    <span class="text-general2 f-14"> ${el.seriesName} </span>
                                </div>
                                <div class="col-auto px-0 text-right pr-0">
                                    <span class="text-general2 ml-3 f-14">${valor} </span>
                                </div>
                                <div class="col-2 text-right px-0">
                                    <span class="text-general2 f-14 ml-3"> ${Math.round(porcentaje)} % </span>
                                </div>
                            </div>
                            `
                        }).join('')

                        return `
                        <span class="text-general f-600 pl-4">${last.name} </span></br>
                        <p style="padding-top:8px;"></p>
                        <i class="icon-brightness f-14 text-general" />
                        <span class="text-general2 f-14"> Variacion ${lastValor}%<span> </br>
                        <p style="padding-top:15px;"></p>
                        <span class="text-general2 f-14 pl-4"> Cedis </span> </br>
                        ${cedis}

                        `;
                    }
                },
                grid: {
                    bottom: '5%'
                },
                legend: {
                    data: cedis,
                    top: '4%',
                    textStyle: {
                        color: '#707070',
                        fontSize: 14
                    }
                },
                xAxis: {
                    data: meses,
                    axisLine: {
                        show: true, //隐藏X轴轴线
                        lineStyle: {
                            color: '#3d5269',
                            width: 1
                        }
                    },
                    axisTick: {
                        show: false, //隐藏X轴刻度
                        alignWithLabel: true
                    },
                    axisLabel: {
                        show: false,
                        textStyle: {
                            color: '#396A87', //X轴文字颜色
                            fontSize: 14
                        },
                        interval: 0,
                        rotate: 30
                    }
                },
                yAxis: [
                    {
                        type: 'value',
                        splitLine: {
                            show: true,
                            lineStyle: {
                                width: 1,
                                color: '#F0F0F0'
                            }
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                width: 1,
                                color: '#F0F0F0'
                            }
                        },
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#396A87',
                                fontSize: 14
                            }
                        }
                    },
                    {
                        type: 'value',
                        position: 'right',
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: true,
                            lineStyle: {
                                width: 1,
                                color: '#F0F0F0'
                            }
                        },
                        axisLabel: {
                            show: true,
                            formatter: '{value} %', //右侧Y轴文字显示
                            textStyle: {
                                color: '#396A87',
                                fontSize: 14
                            }
                        }
                    }
                ],
                series: series
            }
        }
    },
    watch: {
        id_cedis(val){
            if(val == null) return
            this.toggleOnAllLoading()
            this.init()
        },
        id_moneda(val){
            if(val == null) return
            this.toggleOnAllLoading()
            this.init()
        },
    },
    async mounted(){
        console.log(this.graficasIndex[7]), 'console log kpi';
        this.graficasIndex[7].descripcion = `Total de descargas por usuarios unicos de la app ${this.$config.vendedor}`
        this.graficasIndex[7].title = `DESCARGAS - ${this.$config.vendedor} primer navegacion`
        this.graficasIndex[8].title = `DESCARGAS - ${this.$config.cliente} primer navegacion`
        this.graficasIndex[9].title = `MAU - ${this.$config.cliente} diferentes`
        this.graficasIndex[10].title = `MAU - ${this.$config.cliente} diferentes`
        this.graficasIndex[11].title = `TOTALS USERS - Cantidad de ${this.$config.vendedor}`
        this.graficasIndex[12].title = `TOTALS USERS - Cantidad de ${this.$config.cliente}`
        this.graficasIndex[13].title = `SESIONES - ${this.$config.vendedor} arranca app`
        this.graficasIndex[15].title = `Promedio de pedidos por ${this.$config.vendedor}`
        this.graficasIndex[17].title = `CSAT - Satisfaccíon ${this.$config.vendedor}`
        this.graficasIndex[18].title = `NPS - Calificación ${this.$config.vendedor + 's' }`
        await this.init()
    },
    methods: {
        async init(){
            await this.firstSection()
            await this.secondSection()
            await this.thirdSection()
            await this.fourthSection()
            await this.fifthSection()
            await this.sixthSection()
            await this.seventhSection()
            await this.eighthSection()

        },
        async firstSection(){
            try {
                this.loadingFirst = true;

                const { data } = await kpi.firstSection();
                this.ingresoTotalSinImpuesto = data.ingresoTotalSinImpuesto;
                this.costoProducto = data.costoProducto;
                this.cantidadPedidos = data.cantidadPedidos;
            } catch (error){
                this.error_catch(error);
            } finally {
                this.loadingFirst = false;
            }
        },
        async secondSection(){
            try {
                this.loadingSecond = true;

                const { data } = await kpi.secondSection();
                this.ticketPromedio = data.ticketPromedio;
                this.totalUtilidadBruta = data.totalUtilidadBruta;
                this.utilidadBruta = data.utilidadBruta;
            } catch (error){
                this.error_catch(error);
            } finally {
                this.loadingSecond = false;
            }
        },
        async thirdSection(){
            try {
                this.loadingThird = true;

                const { data } = await kpi.thirdSection();
                this.utilidadBrutaGMV = data.utilidadBrutaGMV;
                this.leecherosPrimeraNavegacion =
                    data.leecherosPrimeraNavegacion;
                this.clientesPrimeraNavegacion = data.clientesPrimeraNavegacion;
            } catch (error){
                this.error_catch(error);
            } finally {
                this.loadingThird = false;
            }
        },
        async fourthSection(){
            try {
                this.loadingFourth = true;

                const { data } = await kpi.fourthSection();
                this.leecherosDiferentes = data.leecherosDiferentes;
                this.clientesDiferentes = data.clientesDiferentes;
                this.cantidadLeecheros = data.cantidadLeecheros;
            } catch (error){
                this.error_catch(error);
            } finally {
                this.loadingFourth = false;
            }
        },
        async fifthSection(){
            try {
                this.loadingFifth = true;

                const { data } = await kpi.fifthSection();
                this.cantidadClientesRegistrados =
                    data.cantidadClientesRegistrados;
                this.cantidadArrancaAppLeechero =
                    data.cantidadArrancaAppLeechero;
                this.sesionesUnicasPedido = data.sesionesUnicasPedido;
            } catch (error){
                this.error_catch(error);
            } finally {
                this.loadingFifth = false;
            }
        },
        async sixthSection(){
            try {
                this.loadingSixth = true;

                const { data } = await kpi.sixthSection();
                this.promedioPedidos = data.promedioPedidos;
                this.frecuenciaPedidosDia = data.frecuenciaPedidosDia;
                this.satisfaccionUsuario = data.satisfaccionUsuario;
            } catch (error){
                this.error_catch(error);
            } finally {
                this.loadingSixth = false;
            }
        },
        async seventhSection(){
            try {
                this.loadingSeventh = true;

                const { data } = await kpi.seventhSection();
                this.calificacionPedido = data.calificacionPedido;
                this.retencionUsuarioMes = data.retencionUsuarioMes;
                this.perdidaUsuarioMes = data.perdidaUsuarioMes;
            } catch (error){
                this.error_catch(error);
            } finally {
                this.loadingSeventh = false;
            }
        },
        async eighthSection(){
            try {
                this.loadingEighth = true;

                const { data } = await kpi.eighthSection();
                this.lifeTime = data.lifeTime;
                await this.inventario2()
            } catch (error){
                this.error_catch(error);
            } finally {
                this.loadingEighth = false;
            }
        },
        async inventario2(){
            try {
                const { data } = await kpi.inventario2();

                this.inventarioData = data


            } catch (error){
                this.error_catch(error);
            }
        },

        goTo(element){
            const el = document.getElementById(element);
            el.scrollIntoView({ behavior: "smooth" });
        },
        toggleOnAllLoading(){
            this.loadingFirst = true
            this.loadingSecond = true
            this.loadingThird = true
            this.loadingFourth = true
            this.loadingFifth = true
            this.loadingSixth = true
            this.loadingSeventh = true
            this.loadingEighth = true
        },
        inforGrafica(titleModal){
            this.tituloModal = titleModal
            this.$refs.modalInfoGrafica.toggle();
        }
    },
};
</script>
<style lang="scss" scoped>
.btn-index {
    color: #707070;
    text-decoration: none !important;
    font-size: 15px;
    &:hover {
        color: var(--color-general);
    }
}
</style>
